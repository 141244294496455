
import { emitter } from '@/plugins/events'
import { modalOptions } from '@/type'
import { defineComponent, reactive, computed, onMounted } from 'vue'
export default defineComponent({
  setup() {
    const modalOptions = reactive({
      modelValue: false,
      title: '',
      content: '',
      closeAble: true,
      confirmBtn: true,
      cancelBtn: true,
      confirmBtnText: '確定',
      cancelBtnText: '取消',
      classType: 'success',

      propConfirmEvent: function () {
        // null events
      },
      propCancelEvent: function () {
        // null events
      }
    })

    const classMap = new Map()
    classMap.set('success', {
      class: 'is-success',
      iconClass: 'icon-success c-icon--gn50'
    })
    classMap.set('error', {
      class: 'is-error',
      iconClass: 'icon-error c-icon--rd60'
    })
    classMap.set('alert', {
      class: '',
      iconClass: 'icon-alert c-icon--og40'
    })
    const classType = computed(() => classMap.get(modalOptions.classType))

    function init(PropModalOptions: modalOptions) {
      modalOptions.classType = PropModalOptions.classType ?? 'success'
      modalOptions.modelValue = PropModalOptions.modelValue ?? false
      modalOptions.closeAble = PropModalOptions.closeAble ?? true
      modalOptions.title = PropModalOptions.title ?? ''
      modalOptions.content = PropModalOptions.content ?? ''
      modalOptions.confirmBtn = PropModalOptions.confirmBtn ?? true
      modalOptions.cancelBtn = PropModalOptions.cancelBtn ?? true
      modalOptions.confirmBtnText = PropModalOptions.confirmBtnText ?? '確定'
      modalOptions.cancelBtnText = PropModalOptions.cancelBtnText ?? '取消'
      modalOptions.propConfirmEvent =
        PropModalOptions.confirmBtnClick ??
        function () {
          // use to async slot cancel event
        }
      modalOptions.propCancelEvent =
        PropModalOptions.cancelBtnClick ??
        function () {
          // use to async slot cancel event
        }
    }

    function show() {
      modalOptions.modelValue = true
    }

    function close() {
      modalOptions.modelValue = false
    }

    function done() {
      modalOptions.modelValue = false
    }

    async function handleCancel() {
      const promise: any = modalOptions.propCancelEvent()
      if (promise !== undefined) {
        promise
          .then((res: any) => {
            close()
          })
          .catch((rej: any) => {
            console.log('cancel modal fail')
          })
      } else {
        close()
      }
    }

    async function handleConfirm() {
      const promise: any = modalOptions.propConfirmEvent()
      if (promise !== undefined) {
        promise
          .then((res: any) => {
            done()
          })
          .catch((rej: any) => {
            console.log('confirm modal fail')
          })
      } else {
        done()
      }
    }

    onMounted(() => {
      emitter.on('addModal', init)
      emitter.on('showModal', show)
      emitter.on('closeModal', close)
    })

    return {
      modalOptions,
      handleCancel,
      handleConfirm,
      classType
    }
  }
})
