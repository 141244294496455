import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "l-mask__container" }
const _hoisted_2 = { class: "c-modal__container" }
const _hoisted_3 = { class: "c-modal__icon" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "c-modal__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.modalOptions.modelValue)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: ".l-container"
      }, [
        _createElementVNode("div", {
          class: "l-mask",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modalOptions.closeAble && _ctx.handleCancel))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["c-modal", _ctx.classType.class])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("i", {
                    class: _normalizeClass(["c-icon c-icon--fsXl", _ctx.classType.iconClass])
                  }, null, 2)
                ]),
                _createElementVNode("div", {
                  class: "c-modal__status",
                  innerHTML: _ctx.modalOptions.content
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.modalOptions.cancelBtn)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "c-modal__btn",
                      type: "button",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)), ["stop"]))
                    }, _toDisplayString(_ctx.modalOptions.cancelBtnText), 1))
                  : _createCommentVNode("", true),
                (_ctx.modalOptions.confirmBtn)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "c-modal__btn",
                      type: "button",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)), ["stop"]))
                    }, _toDisplayString(_ctx.modalOptions.confirmBtnText), 1))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}